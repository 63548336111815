import $ from 'jquery';
import 'slick-carousel/slick/slick';

$(document).ready(() => {
  const $slider = $('.js-slider');

  $slider.on('init', () => {
    $('.slider-wrap').addClass('is-visible');
  });

  $slider.slick({
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    adaptiveHeight: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    responsive: [{
      breakpoint: 990,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
      },
    }],
  });
});
